import moment from 'moment';
import FileDownload from 'js-file-download';
import { put, call, fork, take, takeEvery } from 'redux-saga/effects';
import { sendYandexMetrikaEvent } from 'common/utils';

import { REQUEST } from 'redux-config/reduxHelpers';
import { acceptError } from 'common/_redux';
import { getPaymentSystemSlugs } from 'common/_api';
import { SubmissionError } from 'redux-form';

import { setRedirect } from '../../_redux/actions';
import { getBackofficeCountersSaga } from '../../_redux/commonSagas/sagas';

import * as api from '../api';
import * as ActionTypes from './actions';

export function* getDepositSaga(id) {
  try {
    const { status, data } = yield call(api.getDeposit, id);
    if (status < 300) {
      yield put(ActionTypes.getDeposit.success(data));
    } else {
      yield put(ActionTypes.getDeposit.failure(data.detail));
      yield put(setRedirect());
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getDeposit.failure());
    throw e;
  }
}

export function* getDepositWatcherSaga() {
  while (true) {
    const { id } = yield take(ActionTypes.GET_DEPOSIT[REQUEST]);
    yield call(getDepositSaga, id);
  }
}

export function* actionDepositSaga(id, actionType) {
  try {
    const { status, data } = yield call(api.actionDeposit, id, actionType);
    if (status < 300) {
      yield put(ActionTypes.actionDeposit.success(data));
      const EVENT_NAME = 'Deposit';
      sendYandexMetrikaEvent(EVENT_NAME);
      yield call(getDepositSaga, id);
      yield call(getBackofficeCountersSaga, id);
    } else {
      yield put(ActionTypes.actionDeposit.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.actionDeposit.failure());
    throw e;
  }
}

export function* actionDepositWatcherSaga() {
  while (true) {
    const { id, actionType } = yield take(ActionTypes.ACTION_DEPOSIT[REQUEST]);
    yield call(actionDepositSaga, id, actionType);
  }
}

export function* getDepositsSaga({ search }) {
  try {
    const { status, data } = yield call(api.getDeposits, search);
    if (status < 300) {
      yield put(ActionTypes.getDeposits.success(data));
    } else {
      yield put(ActionTypes.getDeposits.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getDeposits.failure());
    throw e;
  }
}

export function* getDepositsWatcherSaga() {
  while (true) {
    const { search } = yield take(ActionTypes.GET_DEPOSITS[REQUEST]);
    yield call(getDepositsSaga, search);
  }
}

export function* getUserDepositsSaga(user) {
  try {
    const { status, data } = yield call(api.getUserDeposits, user);
    if (status < 300) {
      yield put(ActionTypes.getUserDeposits.success(data));
      return { depositsAreLoaded: true, data };
    }
    yield put(ActionTypes.getUserDeposits.failure(data.detail));
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getUserDeposits.failure());
    throw e;
  }
}

export function* getUserDepositsWatcherSaga() {
  while (true) {
    const { user } = yield take(ActionTypes.GET_USER_DEPOSITS[REQUEST]);
    yield call(getUserDepositsSaga, user);
  }
}

export function* getDepositProofSaga(id) {
  try {
    const { status, data } = yield call(api.getDepositProof, id);
    if (status < 300) {
      yield put(ActionTypes.getDepositProof.success(data));
    } else {
      yield put(ActionTypes.getDepositProof.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getDepositProof.failure());
    throw e;
  }
}

export function* getDepositProofWatcherSaga() {
  while (true) {
    const { id } = yield take(ActionTypes.GET_DEPOSIT_PROOF[REQUEST]);
    yield call(getDepositProofSaga, id);
  }
}

export function* getPaymentProofSaga(id, isDeposit) {
  try {
    const { status, data } = yield call(api.getPaymentProof, id, isDeposit);
    if (status < 300) {
      yield put(ActionTypes.getPaymentProof.success(data));
    } else {
      yield put(ActionTypes.getPaymentProof.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getPaymentProof.failure());
    throw e;
  }
}

export function* getPaymentProofWatcherSaga() {
  while (true) {
    const { id, isDeposit } = yield take(ActionTypes.GET_PAYMENT_PROOF[REQUEST]);
    yield call(getPaymentProofSaga, id, isDeposit);
  }
}

export function* verifyDepositProofSaga({ id, doc }) {
  try {
    yield call(api.verifyDepositProof, id, doc);
    yield put(ActionTypes.verifyDepositProof.success(doc));
    yield call(getDepositSaga, id);
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.verifyDepositProof.failure(doc));
    throw e;
  }
}

export function* verifyDepositProofWatcherSaga() {
  yield takeEvery(ActionTypes.VERIFY_DEPOSIT_PROOF[REQUEST], verifyDepositProofSaga);
}

export function* rejectDepositProofSaga({ id, ...values }) {
  try {
    const { data, status } = yield call(api.rejectDepositProof, id, values);
    if (status < 300) {
      yield put(ActionTypes.rejectDepositProof.success(data));
      yield call(getDepositSaga, id);
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.rejectDepositProof.failure());
    throw e;
  }
}
export function* rejectDepositProofWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.rejectDepositProof.REQUEST);
    yield call(rejectDepositProofSaga, payload);
  }
}

export function* getDepositPaymentSystemsSaga() {
  try {
    const { data } = yield call(getPaymentSystemSlugs);
    yield put(ActionTypes.getDepositPaymentSystems.success(data));
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.getDepositPaymentSystems.failure());
  }
}

export function* getDepositPaymentSystemsWatcherSaga() {
  while (true) {
    yield take(ActionTypes.GET_DEPOSIT_PAYMENT_SYSTEMS[REQUEST]);
    yield call(getDepositPaymentSystemsSaga);
  }
}

export function* getWithdrawalSaga(id) {
  try {
    const { status, data } = yield call(api.getWithdrawal, id);
    if (status < 300) {
      yield put(ActionTypes.getWithdrawal.success(data));
      return { withdrawalIsLoaded: true, data };
    }
    yield put(ActionTypes.getWithdrawal.failure(data.detail));
    yield put(setRedirect());
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getWithdrawal.failure());
    throw e;
  }
}

export function* getWithdrawalWatcherSaga() {
  while (true) {
    const { id } = yield take(ActionTypes.GET_WITHDRAWAL[REQUEST]);
    yield call(getWithdrawalSaga, id);
  }
}

export function* actionWithdrawalSaga({ id, actionType }) {
  try {
    const { status, data } = yield call(api.actionWithdrawal, id, actionType);
    if (status < 300) {
      yield put(ActionTypes.actionWithdrawal.success(data));
      yield call(getWithdrawalSaga, id);
      yield call(getBackofficeCountersSaga);
    } else if (status === 409) {
      yield put(ActionTypes.setWithdrawalError.success(data.detail));
      yield put(ActionTypes.actionWithdrawal.failure());
    } else {
      yield put(ActionTypes.actionWithdrawal.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.actionWithdrawal.failure());
    throw e;
  }
}

export function* actionWithdrawalWatcherSaga() {
  yield takeEvery(ActionTypes.ACTION_WITHDRAWAL[REQUEST], actionWithdrawalSaga);
}

export function* getWithdrawalsSaga({ search }) {
  try {
    const { status, data } = yield call(api.getWithdrawals, search);
    if (status < 300) {
      yield put(ActionTypes.getWithdrawals.success(data));
      return { withdrawalsAreLoaded: true, data };
    }
    yield put(ActionTypes.getWithdrawals.failure(data.detail));
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getWithdrawals.failure());
    throw e;
  }
}

export function* getWithdrawalsWatcherSaga() {
  while (true) {
    const { search } = yield take(ActionTypes.GET_WITHDRAWALS[REQUEST]);
    yield call(getWithdrawalsSaga, search);
  }
}

export function* getUserWithdrawalsSaga(user) {
  try {
    const { status, data } = yield call(api.getUserWithdrawals, user);
    if (status < 300) {
      yield put(ActionTypes.getUserWithdrawals.success(data));
      return { depositsAreLoaded: true, data };
    }
    yield put(ActionTypes.getUserWithdrawals.failure(data.detail));
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getUserWithdrawals.failure());
    throw e;
  }
}

export function* getUserWithdrawalsWatcherSaga() {
  while (true) {
    const { user } = yield take(ActionTypes.GET_USER_WITHDRAWALS[REQUEST]);
    yield call(getUserWithdrawalsSaga, user);
  }
}

export function* getWithdrawalPaymentSystemsListSaga() {
  try {
    const { data } = yield call(getPaymentSystemSlugs);
    yield put(ActionTypes.getWithdrawalPaymentSystems.success(data));
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.getWithdrawalPaymentSystems.failure());
  }
}

export function* getWithdrawalPaymentSystemsWatcherSaga() {
  while (true) {
    yield take(ActionTypes.GET_WITHDRAWAL_PAYMENT_SYSTEMS[REQUEST]);
    yield call(getWithdrawalPaymentSystemsListSaga);
  }
}

export function* rejectDepositSaga(payload) {
  try {
    const { id, ...values } = payload;
    const { data, status } = yield call(api.rejectDeposit, id, values);
    if (status < 300) {
      yield put(ActionTypes.rejectDeposit.success(data));
      yield call(getDepositSaga, id);
      yield call(getBackofficeCountersSaga);
    } else {
      yield put(ActionTypes.rejectDeposit.failure());
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.rejectDeposit.failure());
    throw e;
  }
}

export function* rejectDepositWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.rejectDeposit.REQUEST);
    yield call(rejectDepositSaga, payload);
  }
}

export function* getUserTransactionsSaga({ id, dateRange }) {
  try {
    const { status, data } = yield call(api.getUserTransactions, id, dateRange);
    if (status < 300) {
      yield put(ActionTypes.getUserTransations.success(data));
      return { depositsAreLoaded: true, data };
    }
    yield put(ActionTypes.getUserTransations.failure(data.detail));
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getUserTransations.failure());
    throw e;
  }
}

export function* getUserTransactionsWatcherSaga() {
  while (true) {
    const { data } = yield take(ActionTypes.GET_USER_TRANSACTIONS[REQUEST]);
    yield call(getUserTransactionsSaga, data);
  }
}

export function* getOpenOrdersSaga({ userId, accountId, start, end }) {
  try {
    const { status, data } = yield call(api.getOpenOrders, userId, accountId, start, end);
    if (status < 300) {
      yield put(ActionTypes.getOpenOrders.success(data));
    } else {
      yield put(ActionTypes.getOpenOrders.failure(accountId, data.detail));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getOpenOrders.failure(accountId, e));
  }
}
export function* getOpenOrdersWatcherSaga() {
  while (true) {
    const { userId, accountId } = yield take(ActionTypes.GET_OPEN_ORDERS[REQUEST]);
    yield call(getOpenOrdersSaga, userId, accountId);
  }
}

export function* getClosedOrdersSaga({ userId, accountId, start, end }) {
  try {
    const { status, data } = yield call(api.getClosedOrders, userId, accountId, start, end);
    if (status < 300) {
      yield put(ActionTypes.getClosedOrders.success(data));
    } else {
      yield put(ActionTypes.getClosedOrders.failure(accountId, data.detail));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getClosedOrders.failure(accountId, e));
  }
}
export function* getClosedOrdersWatcherSaga() {
  while (true) {
    const data = yield take(ActionTypes.GET_CLOSED_ORDERS[REQUEST]);
    yield call(getClosedOrdersSaga, data);
  }
}

export function* getDelayedOrdersSaga({ userId, accountId, start, end }) {
  try {
    const { status, data } = yield call(api.getDelayedOrders, userId, accountId, start, end);
    if (status < 300) {
      yield put(ActionTypes.getDelayedOrders.success(data));
    } else {
      yield put(ActionTypes.getDelayedOrders.failure(accountId, data.detail));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getClosedOrders.failure(accountId, e));
  }
}
export function* getDelayedOrdersWatcherSaga() {
  while (true) {
    const data = yield take(ActionTypes.GET_DELAYED_ORDERS[REQUEST]);
    yield call(getDelayedOrdersSaga, data);
  }
}
export function* getDeferredOrdersSaga({ userId, accountId, start, end }) {
  try {
    const { status, data } = yield call(api.getDeferredOrders, userId, accountId, start, end);
    if (status < 300) {
      yield put(ActionTypes.getDeferredOrders.success(data));
    } else {
      yield put(ActionTypes.getDeferredOrders.failure(accountId, data.detail));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getClosedOrders.failure(accountId, e));
  }
}
export function* getDeferredOrdersWatcherSaga() {
  while (true) {
    const data = yield take(ActionTypes.GET_DEFERRED_ORDERS[REQUEST]);
    yield call(getDeferredOrdersSaga, data);
  }
}

export function* getEditHistorySaga(id) {
  try {
    const { status, data } = yield call(api.getEditHistory, id);
    if (status < 300) {
      yield put(ActionTypes.getEditHistory.success(data));
    } else {
      yield put(ActionTypes.getEditHistory.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getEditHistory.failure());
    throw e;
  }
}

export function* getEditHistoryWatcherSaga() {
  while (true) {
    const { id } = yield take(ActionTypes.GET_EDIT_HISTORY[REQUEST]);
    yield call(getEditHistorySaga, id);
  }
}

export function* editDepositAmountSaga(payload) {
  try {
    const { id, type, ...values } = payload;
    const { data, status } = yield call(
      type === 'deposit' ? api.editDepositAmount : api.editWithdrawalAmount,
      id,
      values
    );

    if (status < 300) {
      yield put(ActionTypes.editDepositAmount.success(data));
      yield call(type === 'deposit' ? getDepositSaga : getWithdrawalSaga, id);
      yield call(getEditHistorySaga, id);
    } else {
      const error = data.detail ? { _error: data.detail } : { _error: Object.values(data).flat() };
      yield put(ActionTypes.editDepositAmount.failure(new SubmissionError(error)));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.editDepositAmount.failure());
    throw e;
  }
}

export function* editDepositAmountWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.editDepositAmount.REQUEST);
    yield call(editDepositAmountSaga, payload);
  }
}

export function* editAdditionalFieldSaga(payload) {
  try {
    const { id, type, ...values } = payload;
    const paymentType = type === 'withdrawals' ? 'withdraw' : 'deposit';
    const { status, data } = yield call(api.editAdditionalField, id, paymentType, values);

    if (status < 300) {
      yield put(ActionTypes.editAdditionalField.success(data));
      yield call(type === 'deposits' ? getDepositSaga : getWithdrawalSaga, id);
    } else {
      const error = data.detail ? { _error: data.detail } : { _error: Object.values(data).flat() };
      yield put(ActionTypes.editAdditionalField.failure(new SubmissionError(error)));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.editAdditionalField.failure());
    throw e;
  }
}

export function* editAdditionalFieldWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.editAdditionalField.REQUEST);
    yield call(editAdditionalFieldSaga, payload);
  }
}

export function* getApprovalHistorySaga(id) {
  try {
    const { status, data } = yield call(api.getApprovalHistory, id);
    if (status < 300) {
      yield put(ActionTypes.getApprovalHistory.success(data));
    } else {
      yield put(ActionTypes.getApprovalHistory.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getApprovalHistory.failure());
    throw e;
  }
}

export function* getApprovalHistoryWatcherSaga() {
  while (true) {
    const { id } = yield take(ActionTypes.GET_APPROVAL_HISTORY[REQUEST]);
    yield call(getApprovalHistorySaga, id);
  }
}

export function* getApprovalGroupSaga(id) {
  try {
    const { status, data } = yield call(api.getApprovalGroup, id);
    if (status < 300) {
      yield put(ActionTypes.getApprovalGroup.success(data));
    } else {
      yield put(ActionTypes.getApprovalGroup.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getApprovalGroup.failure());
    throw e;
  }
}

export function* getApprovalGroupWatcherSaga() {
  while (true) {
    const { id } = yield take(ActionTypes.GET_APPROVAL_GROUP[REQUEST]);
    yield call(getApprovalGroupSaga, id);
  }
}

export function* rejectWithdrawalSaga(payload) {
  try {
    const { groupId, requestId, ...values } = payload;
    const { data, status } = yield call(api.rejectWithdrawal, groupId, requestId, values);
    if (status < 300) {
      yield put(ActionTypes.rejectWithdrawal.success(data));
      yield call(getApprovalGroupSaga, groupId);
    } else {
      yield put(ActionTypes.rejectWithdrawal.failure());
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.rejectWithdrawal.failure());
    throw e;
  }
}

export function* rejectWithdrawalWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.rejectWithdrawal.REQUEST);
    yield call(rejectWithdrawalSaga, payload);
  }
}

export function* failWithdrawSaga({ id, ...values }) {
  try {
    const { data, status } = yield call(api.rejectWithdraw, id, values);
    if (status < 300) {
      yield put(ActionTypes.failWithdraw.success(data));
      yield call(getWithdrawalSaga, id);
      yield call(getBackofficeCountersSaga);
    } else {
      const formError = new SubmissionError(status === 409 ? { _error: data.detail } : data);
      yield put(ActionTypes.failWithdraw.failure(formError));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.failWithdraw.failure());
    throw e;
  }
}

export function* failWithdrawWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.failWithdraw.REQUEST);
    yield call(failWithdrawSaga, payload);
  }
}

export function* getWithdrawalApprovalGroupListSaga({ search }) {
  try {
    const { status, data } = yield call(api.getWithdrawApprovalGroupList, search);
    if (status < 300) {
      yield put(ActionTypes.getWithdrawApprovalGroupList.success(data));
    } else {
      yield put(ActionTypes.getWithdrawApprovalGroupList.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getWithdrawApprovalGroupList.failure());
    throw e;
  }
}

export function* getWithdrawalApprovalGroupListWatcherSaga() {
  while (true) {
    const data = yield take(ActionTypes.GET_WITHDRAW_APPROVAL_GROUP_LIST[REQUEST]);
    yield call(getWithdrawalApprovalGroupListSaga, data);
  }
}

export function* getApprovalStepsSaga(id) {
  try {
    const { status, data } = yield call(api.getApprovalSteps, id);
    if (status < 300) {
      yield put(ActionTypes.getApprovalSteps.success(data));
    } else {
      yield put(ActionTypes.getApprovalSteps.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getApprovalSteps.failure());
    throw e;
  }
}

export function* getApprovalStepsWatcherSaga() {
  while (true) {
    const { id } = yield take(ActionTypes.GET_APPROVAL_STEPS[REQUEST]);
    yield call(getApprovalStepsSaga, id);
  }
}

export function* approveWithdrawalSaga(payload) {
  try {
    const { groupId, stepId, ...values } = payload;
    const { data, status } = yield call(api.approveWithdrawal, groupId, stepId, values);
    if (status < 300) {
      yield put(ActionTypes.approveWithdrawal.success(data));
      yield call(getApprovalStepsSaga, groupId);
      if (!values.isApproved) {
        yield put(ActionTypes.rejectWithdraw.success(data));
      }
      yield call(getApprovalHistorySaga, groupId);
      yield call(getApprovalGroupSaga, groupId);
    } else if (status === 403) {
      yield put(ActionTypes.approveWithdrawal.failure());
      yield call(getApprovalStepsSaga, groupId);
    } else {
      yield put(ActionTypes.approveWithdrawal.failure());
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.approveWithdrawal.failure());
    throw e;
  }
}

export function* approveWithdrawalWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.approveWithdrawal.REQUEST);
    yield call(approveWithdrawalSaga, payload);
  }
}

export function* getOrderDetailsSaga({ userId, accountId, orderId }) {
  try {
    const { status, data } = yield call(api.getOrderDetails, userId, accountId, orderId);
    if (status < 300) {
      yield put(ActionTypes.getOrderDetails.success(data));
    } else {
      yield put(ActionTypes.getOrderDetails.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getOrderDetails.failure());
    throw e;
  }
}
export function* getOrderDetailsWatcherSaga() {
  while (true) {
    const data = yield take(ActionTypes.GET_ORDER_DETAILS[REQUEST]);
    yield call(getOrderDetailsSaga, data);
  }
}

export function* editOrderSaga({ userId, accountId, orderId, ...payload }) {
  // Filter null and undefined values:
  const filteredPayload = Object.entries(payload).reduce((a, [k, v]) => (v == null ? a : { ...a, [k]: v }), {});
  try {
    const { data, status } = yield call(api.editOrder, userId, accountId, orderId, filteredPayload);

    if (status < 300) {
      yield put(ActionTypes.editOrder.success(data));
    } else {
      yield put(ActionTypes.editOrder.failure());
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.editOrder.failure());
    throw e;
  }
}

export function* editOrderWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.editOrder.REQUEST);
    yield call(editOrderSaga, payload);
  }
}

export function* removeOrderSaga({ userId, accountId, orderId, activeTab }) {
  const start = moment().add(-6, 'day').startOf('day').unix();

  const end = moment().add(1, 'day').startOf('day').unix();

  try {
    const { status, data } = yield call(api.removeOrder, userId, accountId, orderId);
    if (status < 300) {
      if (activeTab === 'opened') {
        yield call(getOpenOrdersSaga, userId, accountId);
      } else {
        yield call(getClosedOrdersSaga, { userId, accountId, start, end });
      }
      yield put(ActionTypes.removeOrder.success(data));
    } else {
      const error = Object.values(data);
      yield put(ActionTypes.removeOrder.failure(error));
      yield put(acceptError(error, true));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.removeOrder.failure());
    throw e;
  }
}
export function* removeOrderWatcherSaga() {
  while (true) {
    const data = yield take(ActionTypes.REMOVE_ORDER[REQUEST]);
    yield call(removeOrderSaga, data);
  }
}

export function* getBackOfficePaymentExportsSaga({ exportsType, search }) {
  try {
    const { status, data } = yield call(api.getBackOfficePaymentExports, exportsType, search);
    if (status < 300) {
      yield put(ActionTypes.getBackOfficePaymentExports.success(data));
    } else {
      yield put(ActionTypes.getBackOfficePaymentExports.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.getBackOfficePaymentExports.failure(e));
  }
}
export function* getBackOfficePaymentExportsWatcherSaga() {
  while (true) {
    const { exportsType, search } = yield take(ActionTypes.GET_BO_PAYMENT_EXPORTS[REQUEST]);
    yield call(getBackOfficePaymentExportsSaga, { exportsType, search });
  }
}

export function* createBackOfficePaymentExportSaga({ exportsType, pushToExportsFunc, ext, filters }) {
  try {
    const { status, data } = yield call(api.createBackOfficePaymentExport, exportsType, ext, filters);
    if (status < 300) {
      yield put(ActionTypes.createBackOfficePaymentExport.success(data));
      pushToExportsFunc();
    } else if (status === 409) {
      yield put(ActionTypes.createBackOfficePaymentExport.failure());
    } else {
      yield put(ActionTypes.createBackOfficePaymentExport.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.createBackOfficePaymentExport.failure(e));
  }
}
export function* createBackOfficePaymentExportWatcherSaga() {
  while (true) {
    const { exportsType, pushToExportsFunc, ext, filters } = yield take(ActionTypes.CREATE_BO_PAYMENT_EXPORT[REQUEST]);
    yield call(createBackOfficePaymentExportSaga, { exportsType, pushToExportsFunc, ext, filters });
  }
}

export function* downloadBackofficePaymentExportSaga({ id, exportsType, name }) {
  try {
    const { status, data } = yield call(api.downloadBackofficePaymentExport, exportsType, id);
    if (status < 300) {
      FileDownload(data, name);
      yield put(ActionTypes.downloadBackofficePaymentExport.success());
    } else {
      yield put(ActionTypes.downloadBackofficePaymentExport.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.downloadBackofficePaymentExport.failure(e));
  }
}

export function* downloadBackofficePaymentExportWatcherSaga() {
  while (true) {
    const { id, exportsType, name } = yield take(ActionTypes.DOWNLOAD_BO_PAYMENT_EXPORT[REQUEST]);
    yield call(downloadBackofficePaymentExportSaga, { id, exportsType, name });
  }
}

export function* getPaymentWithdrawalStatusesSaga() {
  try {
    const { data, status } = yield call(api.getPaymentWithdrawalStatuses);
    if (status < 300) {
      yield put(ActionTypes.getPaymentWithdrawalStatuses.success(data));
    } else {
      yield put(ActionTypes.getPaymentWithdrawalStatuses.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getPaymentWithdrawalStatuses.failure(e));
  }
}
export function* getPaymentWithdrawalStatusesWatcherSaga() {
  while (true) {
    yield take(ActionTypes.GET_PAYMENT_WITHDRAWAL_STATUSES[REQUEST]);
    yield call(getPaymentWithdrawalStatusesSaga);
  }
}

export function* getPaymentDepositStatusesSaga() {
  try {
    const { data, status } = yield call(api.getPaymentDepositStatuses);
    if (status < 300) {
      yield put(ActionTypes.getPaymentDepositStatuses.success(data));
    } else {
      yield put(ActionTypes.getPaymentDepositStatuses.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getPaymentDepositStatuses.failure(e));
  }
}
export function* getPaymentDepositStatusesWatcherSaga() {
  while (true) {
    yield take(ActionTypes.GET_PAYMENT_DEPOSIT_STATUSES[REQUEST]);
    yield call(getPaymentDepositStatusesSaga);
  }
}

export function* changePaymentStatusSaga({ id, type, ...values }) {
  try {
    const { data, status } = yield call(api.changePaymentStatus, id, values);

    if (status < 300) {
      yield put(ActionTypes.changePaymentStatus.success());
      if (type === 'deposit') {
        yield put(ActionTypes.getDeposit.success(data));
      } else {
        yield put(ActionTypes.getWithdrawal.success(data));
      }
    } else {
      yield put(ActionTypes.changePaymentStatus.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.changePaymentStatus.failure(e));
  }
}

export function* changePaymentStatusWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.changePaymentStatus.REQUEST);
    yield call(changePaymentStatusSaga, payload);
  }
}

export default [
  fork(getDepositWatcherSaga),
  fork(actionDepositWatcherSaga),
  fork(getDepositsWatcherSaga),
  fork(getUserDepositsWatcherSaga),
  fork(getDepositProofWatcherSaga),
  fork(getPaymentProofWatcherSaga),
  fork(verifyDepositProofWatcherSaga),
  fork(rejectDepositProofWatcherSaga),
  fork(getDepositPaymentSystemsWatcherSaga),
  fork(getWithdrawalWatcherSaga),
  fork(actionWithdrawalWatcherSaga),
  fork(getWithdrawalsWatcherSaga),
  fork(getUserWithdrawalsWatcherSaga),
  fork(getWithdrawalPaymentSystemsWatcherSaga),
  fork(rejectWithdrawalWatcherSaga),
  fork(failWithdrawWatcherSaga),
  fork(rejectDepositWatcherSaga),
  fork(getUserTransactionsWatcherSaga),
  fork(getOpenOrdersWatcherSaga),
  fork(getClosedOrdersWatcherSaga),
  fork(getDelayedOrdersWatcherSaga),
  fork(getDeferredOrdersWatcherSaga),
  fork(editDepositAmountWatcherSaga),
  fork(editAdditionalFieldWatcherSaga),
  fork(getEditHistoryWatcherSaga),
  fork(getApprovalHistoryWatcherSaga),
  fork(getApprovalGroupWatcherSaga),
  fork(getWithdrawalApprovalGroupListWatcherSaga),
  fork(getApprovalStepsWatcherSaga),
  fork(approveWithdrawalWatcherSaga),
  fork(getOrderDetailsWatcherSaga),
  fork(editOrderWatcherSaga),
  fork(getBackOfficePaymentExportsWatcherSaga),
  fork(createBackOfficePaymentExportWatcherSaga),
  fork(downloadBackofficePaymentExportWatcherSaga),
  fork(removeOrderWatcherSaga),
  fork(getPaymentWithdrawalStatusesWatcherSaga),
  fork(getPaymentDepositStatusesWatcherSaga),
  fork(changePaymentStatusWatcherSaga),
];
