import { SubmissionError } from 'redux-form';
import { put, call, fork, take } from 'redux-saga/effects';
import { REQUEST } from 'redux-config/reduxHelpers';
import { acceptError } from 'common/_redux';
import * as api from '../api';
import * as ActionTypes from './actions';

export function* getApprovePhaseListSaga() {
  try {
    const { status, data } = yield call(api.getApprovePhaseList);
    if (status < 300) {
      yield put(ActionTypes.getApprovePhaseList.success(data));
    } else {
      yield put(ActionTypes.getApprovePhaseList.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.getApprovePhaseList.failure());
    throw e;
  }
}
export function* getApprovePhaseListWatcherSaga() {
  while (true) {
    yield take(ActionTypes.GET_APPROVE_PHASE_LIST[REQUEST]);
    yield call(getApprovePhaseListSaga);
  }
}

export function* mutateApprovePhaseSaga(payload) {
  const transformedFormValues = Object.values(payload).map(phase => {
    const conds = phase.conditions ? phase.conditions : {};

    const { hasAmountCondition, maxNumberOfWithdrawalsCondition, maxWithdrawalAmountCondition, ...conditionFields } =
      conds;

    if (!hasAmountCondition) delete conditionFields.amount;
    if (!maxNumberOfWithdrawalsCondition) delete conditionFields.max_auto_withdrawals;
    if (!maxWithdrawalAmountCondition) delete conditionFields.max_cumulative_amount;

    const conditions = Object.entries(conditionFields).map(([key, value]) => ({ type: key, value }));

    return { ...phase, conditions };
  });
  try {
    const { status, data } = yield call(api.mutateApprovePhase, transformedFormValues);
    if (status < 300) {
      yield put(ActionTypes.mutateApprovePhase.success(data));
    } else {
      const error = data.error ? data.error : data;
      yield put(ActionTypes.mutateApprovePhase.failure(new SubmissionError(error)));
    }
  } catch (e) {
    yield put(acceptError(e));
    yield put(ActionTypes.mutateApprovePhase.failure(e));
  }
}
export function* mutateApprovePhaseWatcherSaga() {
  while (true) {
    const { payload } = yield take(ActionTypes.mutateApprovePhase.REQUEST);
    yield call(mutateApprovePhaseSaga, payload);
  }
}

export function* deleteApprovePhaseSaga({ phaseId }) {
  try {
    const { status, data } = yield call(api.deleteApprovePhase, phaseId);
    if (status < 300) {
      yield put(ActionTypes.deleteApprovePhase.success());
      yield call(getApprovePhaseListSaga);
    } else {
      yield put(ActionTypes.deleteApprovePhase.failure(data.detail));
    }
  } catch (e) {
    yield put(acceptError(e, true));
    yield put(ActionTypes.deleteApprovePhase.failure(e));
  }
}
export function* deleteApprovePhaseWatcherSaga() {
  while (true) {
    const data = yield take(ActionTypes.DELETE_APPROVE_PHASE[REQUEST]);
    yield call(deleteApprovePhaseSaga, data);
  }
}

export default [
  fork(getApprovePhaseListWatcherSaga),
  fork(mutateApprovePhaseWatcherSaga),
  fork(deleteApprovePhaseWatcherSaga),
];
