import axios from 'axios';

const validateStatus = status => status < 500;

export const getDeposits = search => axios.get(`/backoffice/deposit/`, { params: { ...search } }, {});
export const getUserDeposits = user => axios.get(`/backoffice/user/${user}/deposit/`);
export const getDeposit = id => axios.get(`/backoffice/deposit/${id}/`, { validateStatus });
export const actionDeposit = (id, actionType) => axios.post(`/backoffice/deposit/${id}/${actionType}/`);
export const rejectDeposit = (id, data) => axios.post(`/backoffice/deposit/${id}/fail/`, { ...data });
export const editDepositAmount = (id, data) => axios.put(`/backoffice/deposit/${id}/edit_value/`, { ...data });
export const getDepositProof = id =>
  axios.get(`/backoffice/deposit/${id}/proof/`, {
    responseType: 'blob',
    validateStatus,
  });

export const getPaymentProof = id =>
  axios.get(`/payments/request/${id}/proof/`, { responseType: 'blob', validateStatus });

export const verifyDepositProof = id => axios.post(`/backoffice/deposit/${id}/proof/verify/`);
export const rejectDepositProof = (id, params) => axios.post(`/backoffice/deposit/${id}/proof/reject/`, { ...params });

export const getWithdrawals = search => axios.get(`/backoffice/withdraw/`, { params: { ...search } });
export const getUserWithdrawals = user => axios.get(`/backoffice/user/${user}/withdraw/`);
export const getWithdrawal = id => axios.get(`/backoffice/withdraw/${id}/`, { validateStatus });
export const actionWithdrawal = (id, actionType) =>
  axios.post(`/backoffice/withdraw/${id}/${actionType}/`, { validateStatus: status => status < 300 || status === 409 });
export const rejectWithdrawal = (groupId, requestId, data) =>
  axios.post(`/backoffice/withdraw_approval_system/groups/${groupId}/requests/${requestId}/reject/`, {
    ...data,
  });
export const rejectWithdraw = (id, data) => axios.post(`/backoffice/withdraw/${id}/reject/`, { ...data });
export const approveWithdrawal = (groupId, stepId, data) =>
  axios.put(
    `/backoffice/withdraw_approval_system/groups/${groupId}/approvals/${stepId}/`,
    {
      ...data,
    },
    { validateStatus: status => status < 300 || status === 409 }
  );
export const editWithdrawalAmount = (id, data) => axios.post(`/backoffice/withdraw/${id}/edit_value/`, { ...data });

export const getOpenOrders = (userId, accountId, start, end) =>
  axios.get(`/backoffice/user/${userId}/account/${accountId}/open_trades/`, { params: { start, end } });
export const getClosedOrders = (userId, accountId, start, end) =>
  axios.get(`/backoffice/user/${userId}/account/${accountId}/closed_trades/`, { params: { start, end } });
export const getDelayedOrders = (userId, accountId, start, end) =>
  axios.get(`/backoffice/user/${userId}/account/${accountId}/delayed_trades/`, { params: { start, end } });
export const getDeferredOrders = (userId, accountId, start, end) =>
  axios.get(`/backoffice/user/${userId}/account/${accountId}/deferred_orders/`, { params: { start, end } });
export const getEditHistory = id => axios.get(`/backoffice/deposit/${id}/edit_history/`);
export const getOrderDetails = (userId, accountId, orderId) =>
  axios.get(`/backoffice/user/${userId}/account/${accountId}/change_trade/${orderId}/`);
export const editOrder = (userId, accountId, orderId, payload) =>
  axios.patch(`/backoffice/user/${userId}/account/${accountId}/change_trade/${orderId}/`, { id: orderId, ...payload });
export const removeOrder = (userId, accountId, orderId) =>
  axios.delete(`/backoffice/user/${userId}/account/${accountId}/change_trade/${orderId}/`, { id: orderId });

export const getUserTransactions = (id, dateRange) =>
  axios.get(`/backoffice/user/${id}/transaction_history/`, { params: { dateRange } });
export const getApprovalHistory = accountId =>
  axios.get(`/backoffice/withdraw_approval_system/groups/${accountId}/history/`);
export const getApprovalGroup = groupId => axios.get(`/backoffice/withdraw_approval_system/groups/${groupId}/`);
export const getWithdrawApprovalGroupList = search =>
  axios.get(`/backoffice/withdraw_approval_system/groups/`, { params: { ...search } });
export const getApprovalSteps = groupId => axios.get(`/backoffice/withdraw_approval_system/groups/${groupId}/steps/`);

export const createBackOfficePaymentExport = (exportsType, ext, filters) =>
  axios.post(`/reports/${exportsType}/`, { ext, filters });

export const getBackOfficePaymentExports = (exportsType, search) =>
  axios.get(`/reports/${exportsType}/`, { params: { ...search } });

export const downloadBackofficePaymentExport = (exportsType, id) =>
  axios.get(`/reports/${exportsType}/${id}/`, { responseType: 'blob' });
export const getBackofficeManagerList = search => axios.get(`/backoffice/managers/list/?search=${search}`);
export const editAdditionalField = (id, paymentType, data) =>
  axios.post(`/backoffice/${paymentType}/${id}/edit_ps_fields/`, { ...data });

export const getPaymentWithdrawalStatuses = () => axios.get('/payments/statuses/withdraw/');
export const getPaymentDepositStatuses = () => axios.get('/payments/statuses/deposit/');
export const changePaymentStatus = (id, data) => axios.post(`/payments/request/${id}/set_status/`, { ...data });
